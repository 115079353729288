// jshint ignore: start
import React, { useState, useRef, useEffect } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { useIntl, FormattedNumber, navigate } from "gatsby-plugin-intl"
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import MyInput from '../tools/MyInput'
import getFirebase from '../components/firebase';
const firebase = getFirebase();

const useStyles = makeStyles(theme => ({
  formroot: {
    width: 170,
    marginTop: 10,
    marginBottom: 20
  },
  selectRoot: {
    paddingRight: 24,
  },
  margin: {
    marginRight: 24
  },
 root: {
     flexGrow: 1,
   },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function PricingContent() {
    const [email, setEmail] = useState("")
    const [payCS, setPayCS] = useState("")
    const [coin, setCoin] = useState("eur")
    const classes = useStyles;
    const stripe = async () => {
      return Stripe('pk_live_kJcTeudTDYkZCCS8Qc27IDzd');
    }
    const intl = useIntl()

    const handleChangePlan = (ev) => {
      navigate('/app/account?changeplan=' + ev.currentTarget.id)
    }

    function authUser() {
      return new Promise(function (resolve, reject) {
        firebase.auth().onAuthStateChanged(function (user) {
          if (user) {
            resolve(user);
          } else {
            reject('User not logged in');
            var gu = localStorage.getItem("gatsbyUser");
            if (gu) {
              localStorage.setItem("gatsbyUser", JSON.stringify({}));
            }
          }
        });
      });
    }

    function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
        if (!ref.current) {
          ref.current = true;
          authUser().then((user) => {
 
          }, (e) => {
            console.log(e);
          });
        }
        ref.current = value
      }, [value]);
      return ref.current;
    }

    const prevValue = usePrevious({});
    useEffect(() => {
      if (prevValue) {
      
      }
    }, [])

    useEffect(() => {

      return () => {
      }
    }, []);

    const disprices = [
        {
          title: 'EUR (Euro)', locale: ["pt"]
        },
        {
          title: 'USD (US Dollar)', locale: ["en"]
        },
    ]

    const handleCoin = (event) => {
      const {name, value} = event.target
      setCoin(value)
    }

    let currencySymbol = ""
    switch (coin) {
      case "eur":
        currencySymbol = "€"
        break;
      case "usd":
        currencySymbol = "$"
        break;
      default:
        break;
    }

    const pricing = {
        eur: {free: 0, lite01: 19.00, lite12: 182.40, standard01: 29.00, standard12: 278.40, branding: 89.00},
        usd: {free: 0, lite01: 23.00, lite12: 220.80, standard01: 33.00, standard12: 316.80, branding: 99.00}
    }

    return (
      <div>
        <SEO title={intl.formatMessage({ id: "pric.pricing" })} />
        {/* console.log(intl.DateTimeFormat().resolvedOptions().timeZone)*/}
        <h1>{intl.formatMessage({ id: "pric.findplan" })}</h1>
        <h3 style={{color: '#666', marginBottom: 40}}>{intl.formatMessage({ id: "pric.fairprices" })}</h3>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Paper elevation={0} className={classes.paper}>
              <h2 style={{margin: 0, padding: '20px 0'}}>FREE</h2>
              <p style={{padding: '0 12px'}}>{intl.formatMessage({ id: "pric.free" })}</p>
              <div style={{textAlign: 'center'}}>
                <span style={{verticalAlign: 'top', fontSize: 20, color: '#333'}}>{currencySymbol}</span>
                <span style={{fontSize: 38, lineHeight: '38px', fontWeight: 600, padding: '0 4px'}}>0</span>
                <span style={{fontSize: 20,letterSpacing: 2}}>/m</span>
              </div>
              <p>
                <FormattedNumber
                    value={0.00}
                    style="currency"
                    currency={coin}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                />{' '}
                {intl.formatMessage({ id: "pric.with_annual"})}
              </p>
              <div style={{width: '100%', textAlign: 'center'}}>
              <Button onClick={handleChangePlan} style={{width: '160px'}} color="secondary" variant="outlined" id="free">{intl.formatMessage({id: "pric.getstarted"})}</Button>
              </div>
              <p style={{fontWeight: 600}}>10.000 e-mails /{intl.formatMessage({id: "pric.month"})}</p>
              <Divider style={{margin: '0 20px'}}/>
              <ul style={{margin: 0, padding: 20, listStyleType: 'none'}}>
                <li style={{padding: '6px 0'}}>{intl.formatMessage({id: "pric.nolimit"})}</li>
                <li style={{padding: '6px 0'}}>{intl.formatMessage({id: "pric.domains"})}:{' '}
                <strong>
                  <FormattedNumber
                      value={pricing[coin]['branding']}
                      style="currency"
                      currency={coin}
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                  />
                </strong>{' '}/{intl.formatMessage({id: "pric.year"})}</li>
              </ul>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper elevation={0} className={classes.paper}>
              <h2 style={{margin: 0, padding: '20px 0'}}>LITE</h2>
              <p style={{padding: '0 12px'}}>{intl.formatMessage({ id: "pric.lite" })}</p>
              <div style={{textAlign: 'center'}}>
                <span style={{verticalAlign: 'top', fontSize: 20, color: '#333'}}>{currencySymbol}</span>
                <span style={{fontSize: 38, lineHeight: '38px', fontWeight: 600, padding: '0 4px'}}>{pricing[coin]['lite01']}</span>
                <span style={{fontSize: 20,letterSpacing: 2}}>/m</span>
              </div>
              <p>
                <FormattedNumber
                  value={pricing[coin]['lite12'] / 12}
                  style="currency"
                  currency={coin}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />{' '}
                {intl.formatMessage({ id: "pric.with_annual"})}
              </p>
              <div style={{width: '100%', textAlign: 'center'}}>
                <Button onClick={handleChangePlan} style={{width: '160px'}} color="secondary" variant="contained" id="lite12">{intl.formatMessage({id: "pric.select"})}</Button>
              </div>
              <p style={{fontWeight: 600}}>50.000 e-mails /{intl.formatMessage({id: "pric.month"})}</p>
              <Divider style={{margin: '0 20px'}}/>
              <ul style={{margin: 0, padding: 20, listStyleType: 'none'}}>
                <li style={{padding: '6px 0'}}>{intl.formatMessage({id: "pric.nolimit"})}</li>
                <li style={{padding: '6px 0'}}>{intl.formatMessage({id: "pric.domains"})}:{' '}
                <strong>
                  <FormattedNumber
                    value={pricing[coin]['branding']}
                    style="currency"
                    currency={coin}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </strong>{' '}/{intl.formatMessage({id: "pric.year"})}</li>
              </ul>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper elevation={0} className={classes.paper}>
              <h2 style={{margin: 0, padding: '20px 0'}}>STANDARD</h2>
              <p style={{padding: '0 12px'}}>{intl.formatMessage({ id: "pric.standard" })}</p>
              <div style={{textAlign: 'center'}}>
                <span style={{verticalAlign: 'top', fontSize: 20, color: '#333'}}>{currencySymbol}</span>
                <span style={{fontSize: 38, lineHeight: '38px', fontWeight: 600, padding: '0 4px'}}>{pricing[coin]['standard01']}</span>
                <span style={{fontSize: 20,letterSpacing: 2}}>/m</span>
              </div>
              <p>
                <FormattedNumber
                    value={pricing[coin]['standard12'] / 12}
                    style="currency"
                    currency={coin}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                />{' '}
                {intl.formatMessage({ id: "pric.with_annual"})}
              </p>
              <div style={{width: '100%', textAlign: 'center'}}>
                <Button onClick={handleChangePlan} style={{width: '160px'}} color="secondary" variant="contained" id="standard12">{intl.formatMessage({id: "pric.select"})}</Button>
              </div>
              <p style={{fontWeight: 600}}>75.000 e-mails /{intl.formatMessage({id: "pric.month"})}</p>
              <Divider style={{margin: '0 20px'}}/>
              <ul style={{margin: 0, padding: 20, listStyleType: 'none'}}>
                <li style={{padding: '6px 0'}}>{intl.formatMessage({id: "pric.nolimit"})}</li>
                <li style={{padding: '6px 0'}}>{intl.formatMessage({id: "pric.domains"})}:{' '}
                <strong>
                  <FormattedNumber
                    value={pricing[coin]['branding']}
                    style="currency"
                    currency={coin}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </strong>{' '}/{intl.formatMessage({id: "pric.year"})}</li>
              </ul>
            </Paper>
          </Grid>
        </Grid>
        <div style={{textAlign: "center", width: '100%', marginTop: 20}}>
          <FormControl style={{width: '240px',  marginBottom: 20, textAlign: "left"}} className={classes.margin}>
              <InputLabel shrink htmlFor="newcontact-input5">
                {intl.formatMessage({id: "pric.display"})}
              </InputLabel>
              <Select
                disabled={false}
                value={coin}
                onChange={handleCoin}
                input={<MyInput name="coin" />}
              >
                <MenuItem value={'usd'}>USD (US Dollar)</MenuItem>
                <MenuItem value={'eur'}>EUR (Euro)</MenuItem>
              </Select>
            </FormControl>
        </div>
       
      </div>
    )
}

const PricingPage = () => {
  return (
    <Layout>
      <div style={{textAlign: 'center', width: '100%',marginLeft: 'auto',marginRight: 'auto'}}>
        <PricingContent/>
      </div>
    </Layout>
  )
}

export default PricingPage